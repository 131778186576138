import React from 'react';
import bs58 from 'bs58';
import './App.css';

class App extends React.Component {
	constructor(props) {
		super(props);

		const search = window.location.search.substring(2);
		const bytes = bs58.decode(search);
		const farm = bytes.toString();

		console.log(farm);

		this.state = {
			farm: farm,
			result: '',
		};

		this.input = React.createRef();
		this.output = React.createRef();
	}

	handleSubmit() {
		const value = this.input.current.value;
		console.log(value);
		const lines = value.split('\n');
		if (lines.length !== 12) {
			this.setState({ result: 'error: should be 12 lines' });
		} else if (!lines.every(x => x.length === 24)) {
			this.setState({ result: 'error: line length not 24' });
		} else {
			const command = '!farm set\n' + value;
			const bytes = Buffer.from(command);
			const enc = bs58.encode(bytes);
			this.setState({ result: 'l' + enc});
		}
	}

	copyToClipboard() {
		this.output.current.select();
		document.execCommand('copy');
	}

	render() {
		const farm = this.state.farm;
		const result = this.state.result;


		return (
			<div>
				<p>First, edit your farm here:</p>

				<textarea ref={this.input} rows='12' cols='24' autoFocus wrap='off'>
					{farm}
				</textarea>

				<br />
				<input type='submit' value='Submit' onClick={() => this.handleSubmit()} />

				<p>Then send this to chat:</p>

				<textarea ref={this.output} value={result} rows='22' cols='24' />

				<br />
				<input type='submit' value='Copy to clipboard' onClick={() => this.copyToClipboard()} />
			</div>

		);
	}
}

export default App;
